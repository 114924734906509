.custom-sidebar {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
}

.custom-sidebar > div {
    flex: 1;
}
.sl-text-base{
    font-size: 14px;
}